<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">กรุณารอสักครู่...</p>
      </div>
    </template>
    <b-card no-body>
      <b-card-body>
        <!-- <div class="d-flex justify-content-between flex-wrap"> -->
        <b-row>
          <b-col lg="6">
            <h3>บัญชีย่อย</h3>
          </b-col>
        </b-row>
        <!-- </div> -->
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        class="position-relative items-center"
        :items="items"
        :fields="fields"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              v-model="data.item.status"
              :checked="data.item.status"
              class="custom-control-info"
              name="check-button"
              switch
              :value="1"
              :unchecked-value="0"
              @change="SubmitStatus(data.item)"
            >
              <span class="switch-icon-left"> เปิด </span>
              <span class="switch-icon-right"> ปิด </span>
            </b-form-checkbox>
          </div>
        </template>
        <template #cell(allow_member)="data">
            <span :class="data.item.allow_member == 'off' ? 'text-danger': data.item.allow_member == 'view' ? 'text-warning' :'text-success'" >{{ data.item.allow_member }}</span>
        </template>
        <template #cell(allow_lotto)="data">
            <span :class="data.item.allow_lotto == 'off' ? 'text-danger': data.item.allow_lotto == 'view' ? 'text-warning' :'text-success'" >{{ data.item.allow_lotto }}</span>
        </template>
        <template #cell(allow_bet)="data">
            <span :class="data.item.allow_bet == 'off' ? 'text-danger': data.item.allow_bet == 'view' ? 'text-warning' :'text-success'" >{{ data.item.allow_bet }}</span>
        </template>
        <template #cell(allow_winlose)="data">
            <span :class="data.item.allow_winlose == 'off' ? 'text-danger': data.item.allow_winlose == 'view' ? 'text-warning' :'text-success'" >{{ data.item.allow_winlose }}</span>
        </template>
        <template #cell(actions)="data">
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="editData(data.item)"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1"
            @click="deleteData(data.item)"
          />
        </template>
      </b-table>
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 ml-1 pb-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0" />
    </b-card>
    <b-modal
      ref="modal-1"
      :title="`แก้ไขบัญชี ${username}`"
      ok-title="Submit"
      @ok.prevent="submit()"
    >
      <div class="row">
        <div class="col-md-6 mt-1">
          fullname
          <b-form-input
            id="fullname"
            v-model="fullname"
            placeholder="fullname"
          />
        </div>
        <div class="col-md-6 mt-1">
          Phone
          <b-form-input
            id="Phone"
            v-model="Phone"
            placeholder="Phone"
            type="number"
          />
        </div>
        <div class="col-md-6 mt-1">
          <b-form-group>
            จัดการสมาชิก
            <b-form-radio-group v-model="management" class="d-flex flex-column">
              <b-form-radio value="off"> ปิด </b-form-radio>
              <b-form-radio value="view"> ดูเท่านั้น </b-form-radio>
              <b-form-radio value="edit"> แก้ไข </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6 mt-1">
          <b-form-group>
            จัดการหวย
            <b-form-radio-group
              v-model="managelotto"
              class="d-flex flex-column"
            >
              <b-form-radio value="off"> ปิด </b-form-radio>
              <b-form-radio value="view"> ดูเท่านั้น </b-form-radio>
              <b-form-radio value="edit"> แก้ไข </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6 mt-1">
          <b-form-group>
            รายงานการแทง
            <b-form-radio-group v-model="managebet" class="d-flex flex-column">
              <b-form-radio value="off"> ปิด </b-form-radio>
              <b-form-radio value="view"> ดูเท่านั้น </b-form-radio>
              <b-form-radio value="edit"> แก้ไข </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="col-md-6 mt-1">
          <b-form-group>
            รายงานแพ้ชนะ
            <b-form-radio-group
              v-model="managewinlose"
              class="d-flex flex-column"
            >
              <b-form-radio value="off"> ปิด </b-form-radio>
              <b-form-radio value="view"> ดูเท่านั้น </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="col-md-6 mt-1">
          Password
          <b-form-input
            id="Password"
            v-model="password"
            placeholder="Password"
            type="password"
          />
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  // BInputGroupPrepend,
  BModal,
  BCard,
  BTable,
  // BAvatar,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BFormInput,
  BButton,
  BCardBody,
  VBToggle,
  BOverlay,
  BIconController,
  BFormCheckbox,
  BPagination,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    // vSelect,
    // BInputGroupPrepend,
    BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    // BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormCheckbox,
    BPagination,
    BFormRadio,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      agentlottodata: {},
      UserData: JSON.parse(localStorage.getItem("userData")),
      search_val: null,
      search_type: 4,
      show: false,
      depositdata: [],
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: "index", label: "no." },
        { key: "username", label: "ยูสเซอร์เนม" },
        { key: "fullname", label: "ชื่อ" },
        { key: "tel", label: "เบอร์โทร" },
        { key: "status", label: "สถานะการใช้งาน" },
        { key: "allow_member", label: "จัดการสมาชิก" },
        { key: "allow_lotto", label: "จัดการหวย" },
        { key: "allow_bet", label: "รายงานการแทง" },
        { key: "allow_winlose", label: "รายงานแพ้ชนะ" },
        { key: "actions", label: "Actions" },
      ],
      items: [],
      management: null,
      managelotto: null,
      managebet: null,
      managewinlose: null,
      ID: null,
      password: null,
      fullname: null,
      Phone: null,
      username: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const params = {
        perPage: this.perPage,
        page: this.currentPage,
      };
      this.$http
        .get("http://127.0.0.1:9999/api/SubAccount/getData", { params })
        .then((response) => {
          this.items = response.data.data;
          this.totalRows = response.data.total;
        });
    },
    SubmitStatus(data) {
      const obj = {
        id: data.id,
        status: data.status,
      };
      this.$http
        .post("http://127.0.0.1:9999/api/SubAccount/update/status", obj)
        .then((response) => {
          if (response.data.status) {
            this.Success("บันทึกข้อมูลเรียบร้อยแล้ว");
          } else {
            this.SwalError("บันทึกข้อมูลไม่สำเร็จ");
          }
          this.getData();
        })
        .catch((err) => {
          this.SwalError(err);
        });
    },
    async submit() {
      const resultCheck = await this.$swal({
        title: `แก้ไขบัญชี ${this.username}`,
        text: `กรุณายืนยันแก้ไขบัญชีย่อย`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__shakeX",
        },
        buttonsStyling: false,
      });
      if (resultCheck.isConfirmed) {
        const obj = {
          id: this.ID,
          password: this.password,
          fullname: this.fullname,
          tel: this.Phone,
          allow_member: this.management,
          allow_lotto: this.managelotto,
          allow_bet: this.managebet,
          allow_winlose: this.managewinlose,
        };
        await this.$http
          .post("http://127.0.0.1:9999/api/SubAccount/update/data", obj)
          .then((response) => {
            if (response.data.status) {
              this.Success(response.data.mes);
              this.$refs["modal-1"].hide();
              this.getData();
            } else {
              this.SwalError(response.data.mes);
            }
          })
          .catch((err) => {
            this.SwalError(err);
          });
      }
      },
    async  deleteData(data) {
         const resultCheck = await this.$swal({
        title: `ลบบัญชี ${data.username}`,
        text: `ยืนยันลบบัญชี ${data.username} หรือไม่ ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        showClass: {
          popup: "animate__animated animate__shakeX",
        },
        buttonsStyling: false,
      });
        if (resultCheck.isConfirmed) {
            const obj = {
                id: data.id,
            }
            this.$http.post("http://127.0.0.1:9999/api/SubAccount/delete", obj).then(response => {
                if (response.data.status) {
                    this.Success(response.data.mes);
                    this.getData();
                } else {
                    this.SwalError(response.data.mes);
                }
            }).catch(err => {
                    this.SwalError(err);
            })
        }
    },
    Success(mes) {
      this.$swal({
        icon: "success",
        title: '<h3 style="color: #141414">บันทึกสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    editData(data) {
      if (data) {
        this.ID = data.id;
        this.management = data.allow_member;
        this.managelotto = data.allow_lotto;
        this.managebet = data.allow_bet;
        this.managewinlose = data.allow_winlose;
        this.fullname = data.fullname;
        this.Phone = data.tel;
        this.username = data.username;
        this.$refs["modal-1"].show();
      } else {
        this.ID = null;
        this.management = null;
        this.managelotto = null;
        this.managebet = null;
        this.managewinlose = null;
        this.password = null;
        this.fullname = null;
        this.Phone = null;
        this.username = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
